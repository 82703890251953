import { FC, ReactNode } from "react";
import { Colors, ITypography } from "./types";

const body = (children: ReactNode, color?: Colors, className?: string) => (
  <div color={color} className={`text-base font-normal leading-normal m-0 p-0 ${className}`}>
    {children}
  </div>
);

const Typography: FC<ITypography> = ({ variant, color, children, className }) => {
  const colorClass = color ? `text-${color}` : "";
  const addtionalClass = className ? `${className} ${colorClass}` : "";
  switch (variant) {
    case "H1":
      return (
        <h1 color={color} className={`text-5xl font-black leading-[3.5rem] m-0 p-0 ${addtionalClass}`}>
          {children}
        </h1>
      );
    case "H2":
      return (
        <h2 color={color} className={`text-4xl font-black leading-[3.0rem] m-0 p-0 ${addtionalClass}`}>
          {children}
        </h2>
      );
    case "H3":
      return (
        <h3 color={color} className={`text-3xl font-black leading-9 m-0 p-0 ${addtionalClass}`}>
          {children}
        </h3>
      );
    case "H4":
      return (
        <h4 color={color} className={`text-2xl font-black leading-8 m-0 p-0 ${addtionalClass}`}>
          {children}
        </h4>
      );
    case "H5":
      return (
        <h5 color={color} className={`text-xl font-semibold leading-7 m-0 p-0 ${addtionalClass}`}>
          {children}
        </h5>
      );
    case "H6":
      return (
        <h6 color={color} className={`text-lg font-semibold leading-6 m-0 p-0 ${addtionalClass}`}>
          {children}
        </h6>
      );
    case "Lead":
      return (
        <div color={color} className={`text-lg font-normal leading-normal m-0 p-0 ${addtionalClass}`}>
          {children}
        </div>
      );
    case "Body":
      return body(children, color, addtionalClass);
    case "BodySpan":
      return (
        <span color={color} className={`text-base font-normal leading-normal m-0 p-0 ${addtionalClass}`}>
          {children}
        </span>
      );
    case "Bold":
      return (
        <div color={color} className={`text-base font-semibold leading-normal m-0 p-0 ${addtionalClass}`}>
          {children}
        </div>
      );
    case "BoldSpan":
      return (
        <span color={color} className={`text-base font-semibold leading-normal m-0 p-0 ${addtionalClass}`}>
          {children}
        </span>
      );
    case "Error":
      return (
        <div color={color} className={`text-sm font-normal leading-normal m-0 p-0 text-red ${addtionalClass}`}>
          {children}
        </div>
      );
    case "Description":
      return (
        <div color={color} className={`text-sm font-normal leading-normal m-0 p-0 ${addtionalClass}`}>
          {children}
        </div>
      );
    case "DescriptionBold":
      return (
        <div color={color} className={`text-sm font-semibold leading-normal m-0 p-0 ${addtionalClass}`}>
          {children}
        </div>
      );
    default:
      return body(children, color, addtionalClass);
  }
};

export default Typography;
