import LogoutButton from "../logoutButton";
import LogoButton from "../logoButton";

interface Props {
  centerId: string;
}

const Header = ({ centerId }: Props) => (
  <div className="container mx-auto">
    <div className="flex justify-between items-center">
      <LogoButton centerId={centerId} />
      <LogoutButton />
    </div>
  </div>
);

export default Header;
