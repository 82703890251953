"use client";

import Icon from "@portal/components/atoms/icons";
import Typography from "@portal/components/atoms/typography";
import { TextResources } from "@portal/assets/text/TextResources";
import { useSession } from "@portal/auth/client/SessionProvider";
import signOut from "@portal/auth/client/api/signOut";

const LogoutButton = () => {
  const { session } = useSession();

  if (session?.status === "authenticated") {
    return (
      <button
        onClick={() => {
          signOut();
        }}
        className="flex space-x-2"
      >
        <Icon name="arrowRightOnRectangleIcon" size="small" />
        <Typography>{TextResources.MENU_LOGOUT}</Typography>
      </button>
    );
  }

  return null;
};

export default LogoutButton;
